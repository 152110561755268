import React from "react";

import Slider from "react-slick";
import UeSvg from "../../assets/img/ueSvg";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const DotationUE = () => {
  const lang = "en";

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Layout
      seoTitle="EU grants"
      lang={lang}
      translationPL="/dotacje-ue/"
      translationDE="/de/eu-zuschusse/"
    >
      <SmallBanner title="EU grants" lang={lang} />
      <section className="dotation-ue-info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2
                className="color--main mb-40"
                title="(Smart Growth Operational Programme)"
              >
                SGOP Sub-measure 1.1.1
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <UeSvg viewBox="46 0 670.6 181.4" />
              <p className="mb-30">
                Palettenwerk Kozik Sp. z o.o. is carrying out a project which
                is co-financed with European Funds and is entitled ‘The
                development of an innovative technology for modular,
                prefabricated timber-based construction by PalettenWerk Kozik
                Spółka Jawna’.
              </p>
              <p className="mb-30">
                The aim of the project is to develop and implement modular,
                prefabricated timber-based construction technology, and thus to
                significantly improve the level of innovation by utilising the
                results of R&D work.
              </p>
              <p>EU funding of the project: 9,113,867.55 PLN</p>
            </div>
          </div>
        </div>
      </section>
      <section className="dotation-ue-info dotation-ue-info--second">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2
                className="color--main mb-40"
                title="Regional Operational Programme"
              >
                ROP for Świętokrzyskie Voivodeship, Measure 2.5
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-7 col-xxl-8 offset-lg-6 offset-xl-5 offset-xxl-4">
              <img
                className="mb-60"
                src={require("../../assets/img/dotation-ue2.png")}
                alt="Paletten"
              />
              <p className="mb-30">
                Palko Spółka z ograniczoną odpowiedzialnością is carrying out a
                project which is co-financed by European Funds and is entitled
                ‘Purchase of innovative machines and equipment for production
                purposes at Palko’.
              </p>
              <p className="mb-30">
                The project provides for product, technological, organisational,
                and marketing changes of an innovative nature. These changes
                will be made possible by the purchase of advanced technologies
                and by implementing the results of R&D work.
              </p>
              <p>EU funding of the project: 888,505.25 PLN</p>
            </div>
          </div>
        </div>
      </section>
      <section className="timeline timeline--small">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-4">
              <h2 className="color--main mb-20">Our projects</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider {...settings} className="timeline__slider">
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations1.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations2.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations3.jpg")})`,
                    }}
                  ></div>
                </div>
                <div className="slider__item">
                  <div
                    className="item__image"
                    style={{
                      backgroundImage: `url(${require("../../assets/img/realizations4.jpg")})`,
                    }}
                  ></div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DotationUE;
